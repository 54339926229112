import { IOrganisationMarketingData } from 'shared';

import { Select } from '../../../../common/Atoms/Select';

const heardAboutOptions = [
  {
    label: `App Marketplace. (Xero, QuickBooks, etc.)`,
    value: `appstore`,
  },
  {
    label: `Search Engine (Google, Bing, etc.)`,
    value: `search`,
  },
  {
    label: `My accountant or bookkeeper`,
    value: `partner`,
  },
  {
    label: `Blog or article`,
    value: `blog`,
  },
  {
    label: `Tradeshow or event`,
    value: `event`,
  },
  {
    label: `Newsletter`,
    value: `newsletter`,
  },
  {
    label: `Social Media`,
    value: `social`,
  },
  {
    label: `Pinch Payments`,
    value: `pinch`,
  },
  {
    label: `Other`,
    value: `other`,
  },
];

const imAOptions = [
  {
    label: `Business`,
    description: `I'm a business sending invoices to my customers`,
    value: `business`,
  },
  {
    label: `Accountant or Bookkeeper for Clients`,
    description: `I'm an accountant or bookkeeper setting up on behalf of my clients`,
    value: `partner`,
  },
  {
    label: `Accountant or Bookkeeper for Myself`,
    description: `I'm an accountant or bookkeeper setting up for my own practice`,
    value: `partner_self`,
  },
  {
    label: `Something else`,
    description: `None of the above apply`,
    value: `other`,
  },
];

interface IWelcomeQuestionsProps {
  welcomeQuestions: IOrganisationMarketingData;
  setWelcomeQuestions: (value: IOrganisationMarketingData) => void;
}

export function WelcomeQuestions({ welcomeQuestions, setWelcomeQuestions }: IWelcomeQuestionsProps) {
  return (
    <div className={ `flex justify-center w-full` }>
      <div className={ `mt-4 space-y-3 min-w-[450px]` }>
        <Select
          label={ `What best describes who you are? ` }
          options={ imAOptions }
          selected={ welcomeQuestions.typeSelfReported }
          onChange={ value => setWelcomeQuestions({ ...welcomeQuestions, typeSelfReported: value.value }) }
          nullable
        />

        <Select
          label={ `How did you hear about us?` }
          options={ heardAboutOptions }
          selected={ welcomeQuestions.sourceSelfReported }
          onChange={ value => setWelcomeQuestions({ ...welcomeQuestions, sourceSelfReported: value.value }) }
          nullable
        />

      </div>
    </div>
  );
}
