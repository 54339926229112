import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { When } from 'react-if';

import { Paragraph } from './Typography/Paragraph';
import { Heading } from './Typography/Heading';

interface ListItemProps {
  item: {
    id?: string | number;
    title: string | React.ReactNode;
    description?: string | React.ReactNode;
    secondaryDescription?: string | React.ReactNode;
    footer?: string | React.ReactNode;
    onClick?: (...args: unknown[]) => void
  }
}

export function ListItem({ item }: ListItemProps) {
  function onClick(e) {
    const val = item.id || item.title;

    item.onClick && item.onClick(e, val);
  }

  return (
    <li>
      <a href={ `#` }
        className={ `block hover:bg-gray-50` }
        onClick={ onClick }>
        <div className={ `flex items-center px-4 py-4 sm:px-6` }>
          <div className={ `min-w-0 flex-1 sm:flex sm:items-center sm:justify-between` }>
            <div className={ `flex text-sm flex-col space-y-2` }>
              <Heading
                size={ `sm` }
                className={ `text-lateGreen-600` }
              >
                { item.title }
              </Heading>
              <Paragraph
                as={ `div` }
                variant={ `subHeading` }
              >
                { item.description }
              </Paragraph>
              <Paragraph
                as={ `div` }
                variant={ `subHeading` }>
                { item.secondaryDescription }
              </Paragraph>
              <When condition={ !!item.footer }>
                <Paragraph
                  variant={ `help` }
                  size={ `xs` }
                >
                  { item.footer }
                </Paragraph>
              </When>
            </div>
          </div>
          <div className={ `ml-5 flex-shrink-0` }>
            <div className={ `flex items-center` }>
              <Paragraph>
                { `View` }
              </Paragraph>
              <ChevronRightIcon className={ `h-5 w-5 text-gray-400` }
                aria-hidden={ `true` } />
            </div>
          </div>
        </div>
      </a>
    </li>
  );
}
