import * as React from 'react';

import { classNames } from '../lib/classNames';

import { Paragraph } from './Typography/Paragraph';

export interface IStrapProps {
  type: `info` | `warning` | `error` | `success` | `frozen`;
  children: React.ReactNode;
  className?: string;
}

const classes = {
  info: `bg-lateGreen-500`,
  warning: `bg-orange-500`,
  error: `bg-red-500`,
  success: `bg-green-500`,
  frozen: `bg-blue-400`,
};

export function Strap({
  type,
  children,
  className,
}: IStrapProps) {
  return (
    <div className={
      classNames(
        `flex h-16 xl:h-12 2xl:h-10 items-center justify-center px-6  sm:px-8 lg:px-10`,
        classes[type],
        className,
      ) }
    >
      <Paragraph
        className={ `text-white text-center` }
      >
        { children }
      </Paragraph>
    </div>
  );
}
