import { DataGridPro, FilterColumnsArgs, GetColumnForNewFilterArgs, GridCellParams, GridColDef, GridCsvExportOptions, GridLogicOperator, GridRowSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, getGridDateOperators, getGridSingleSelectOperators, getGridStringOperators, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionStatus, IContact, langauge } from 'shared';
import { DateTime } from 'luxon';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import { When } from 'react-if';
import { Tooltip } from '@mui/material';
import { BackwardIcon, CheckCircleIcon, ClockIcon, ExclamationCircleIcon, HandRaisedIcon, MagnifyingGlassCircleIcon } from '@heroicons/react/20/solid';

import { DatePopover } from '../../../common/Components/DatePopover';
import { useActions, useActionTableModels } from '../../hooks/useActions';
import { getTableHeight } from '../../lib/helper';
import { WrappedLink } from '../WrappedLink';
import { open } from '../../slices/actionDrawer';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { usePolicies } from '../../hooks/usePolicies';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { useAllowedTypes } from '../../hooks/useAllowedActionTypes';
import { useAccountingSystemTerm } from '../../hooks/useAccountingSystemTerm';
import { ITab, Tabs } from '../../../common/Atoms/Tabs';

import { ActionStatusBadge } from './ActionStatusBadge';
import { ActionQuickFilters } from './ActionQuickFilters';

interface ActionsTableProps {
  omitColumns?: string[];
  smallTabs?: boolean;
}

export function ActionsTable({ omitColumns = [], smallTabs }: ActionsTableProps) {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const { data: actions, isLoading, isFetching } = useActions();
  const { resetModels, currentTab, setCurrentTab } = useActionTableModels();
  const dispatch = useDispatch();
  const { policyFilters } = usePolicies();
  const gridApiRef = useGridApiRef();
  const currentOrg = useGetSelectedOrganisation();
  const { actionTypes } = useAllowedTypes();
  const quoteTerm = useAccountingSystemTerm(`Quote`);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  // The controlled state
  const {
    filterModel,
    sortModel,
    paginationModel,

    setFilterModel,
    setSortModel,
    setPaginationModel,
  } = useActionTableModels();

  // On first mount, reset the filters
  useEffect(() => {
    if (urlParams.has(`persist`)) {
      // Do nothing
    }
    else {
      resetModels();
    }
  }, []);
  // We are on the contact page, so also update the actions query

  function onCellClick(params: GridCellParams) {
    const fieldsWithLinks = [
      `contact`,
      `__check__`,
    ];
    if (fieldsWithLinks.includes(params.field)) {
      return;
    }

    dispatch(open(params.row.id));
  }

  const typeOptions = actionTypes.map(at => ({
    label: langauge.action[at].label,
    value: at,
  }));

  const rows = useMemo(() => {
    const result = actions?.actions.map(action => ({
      ...action,
      typeDisplay: langauge.action[action.type].label,
      statusDisplay: <ActionStatusBadge action={ action }
        size={ `sm` }/>,
      scheduledDisplay: <DatePopover
        date={ action.actionOn }
        labelFormatPreset={ DateTime.DATETIME_MED }
        organisationTimezone={ action.organisationTimezone }
      />,
      executedDisplay: action.executedOn ? <DatePopover
        date={ action.executedOn }
        labelFormatPreset={ DateTime.DATETIME_MED }
        organisationTimezone={ action.organisationTimezone }
      /> : `-`,
      invoiceNumber: action.invoice?.xero_number || action.quote?.number || `-`,
      quoteNumber: action.quote?.number || `-`,
      createdInvoiceNumber: action.penalty?.xero_invoice_number || `-`,
    }));

    return result || [];
  }, [actions?.actions]);

  // When action array changes, reset the autosize grid
  useEffect(() => {
    if (rows.length > 0 && gridApiRef.current?.autosizeColumns) {
      gridApiRef.current.autosizeColumns();
    }
  }, [rows, gridApiRef]);

  const columns: GridColDef<(typeof rows)[number]>[] = useMemo(() => {
    const r: GridColDef<(typeof rows)[number]>[] = [
      {
        field: `type`,
        headerName: `Type`,
        minWidth: 200,
        maxWidth: 240,
        cellClassName: `cursor-pointer`,
        type: `singleSelect`,
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === `is`),
        valueOptions: typeOptions,
        renderCell: ({ row }) => <span className={ `w-full` }>
          <span className={ `flex` }>
            { row.typeDisplay }
            <Paragraph variant={ `link` }
              as={ `span` }>
              <ArrowTopRightOnSquareIcon className={ `h-4 w-4 inline-block ml-1` } />
            </Paragraph>
          </span>
          <When condition={ row.sourcePolicies }>
            { () => (
              <Tooltip title={ `Created by: ${row.sourcePolicies === `SYSTEM POLICY` ? `manual trigger` : row.sourcePolicies }` }>
                <span>
                  <Paragraph
                    variant={ `help` }
                    as={ `span` }
                    className={ `block truncate` }
                  >
                    { row.sourcePolicies }
                  </Paragraph>
                </span>
              </Tooltip>
            ) }
          </When>
        </span>,
      },
      {
        field: `status`,
        // flex: 0.75,
        // minWidth: 120,
        cellClassName: `cursor-pointer`,
        headerName: `Status`,
        filterable: false,
        renderCell: ({ row }) => row.statusDisplay,
      },
      {
        field: `contact`,
        headerName: `Contact`,
        type: `string`,
        // flex: 0.75,
        filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
        renderCell: ({ row }) => row.contact?.id ? (
          <span>
            <WrappedLink to={ `/contacts/${row.contact.id}` }>
              <span className={ `text-blue-600 underline whitespace-wrap` }>
                { row.contact.name }
              </span>
            </WrappedLink>
            <When condition={ row.payload?.contactPerson?.email }>
              <Paragraph
                variant={ `help` }
                as={ `span` }
                className={ `block` }
              >
                { row.payload?.contactPerson?.email }
              </Paragraph>
            </When>

          </span>
        ) : row.invoice?.xero_contact_name || row.contact?.name || `-`,
        sortable: false,
        valueFormatter: value => {

          return (value as IContact)?.name || `-`;
        },
      },
      {
        field: `invoiceNumber`,
        headerName: `Invoice / ${quoteTerm}`,
        cellClassName: `cursor-pointer`,
        // flex: 0.6,
        filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
        type: `string`,
      },
      // {
      //   field: `quoteNumber`,
      //   headerName: quoteTerm,
      //   cellClassName: `cursor-pointer`,
      //   filterable: false,
      //   sortable: false,
      //   // flex: 0.6,
      //   // filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
      //   type: `string`,
      // },
      {
        field: `actionOn`,
        headerName: `Scheduled`,
        renderCell: ({ row }) => row.scheduledDisplay,
        filterOperators: getGridDateOperators(true).filter(op => {
          return [ `onOrAfter`, `onOrBefore`].includes(op.value);
        }),
      },
      {
        field: `executedOn`,
        headerName: `Actioned On`,
        cellClassName: `cursor-pointer`,
        type: `custom`,
        filterOperators: getGridDateOperators(true).filter(op => {
          return [ `onOrAfter`, `onOrBefore`].includes(op.value);
        }),
        renderCell: ({ row }) => row.executedDisplay,
      },
      // Hidden
      {
        field: `policyId`,
        headerName: `Policy`,
        type: `singleSelect`,
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === `is`),
        valueOptions: policyFilters,
      },
      {
        field: `createdInvoiceNumber`,
        headerName: `Created Invoice`,
        type: `string`,
      },
    ];

    // Apply some duplicates
    return r.map(c => ({
      ...c,
      editable: false,
      headerClassName: `text-md text-gray-800 font-bold`,
    }))
      .filter(r => !omitColumns.includes(r.field));
  }, [rows, policyFilters, omitColumns, quoteTerm]);

  const filterColumns = ({ field, columns, currentFilters }: FilterColumnsArgs) => {
    // remove already filtered fields from list of columns
    const filteredFields = currentFilters?.map(item => item.field);

    return columns
      .filter(
        colDef =>
          colDef.filterable &&
          (colDef.field === field || !filteredFields.includes(colDef.field)),
      )
      .map(column => column.field);
  };

  const getColumnForNewFilter = ({
    currentFilters,
    columns,
  }: GetColumnForNewFilterArgs) => {
    const filteredFields = currentFilters?.map(({ field }) => field);
    const columnForNewFilter = columns
      .filter(
        colDef => colDef.filterable && !filteredFields.includes(colDef.field),
      )
      .find(colDef => colDef.filterOperators?.length);

    return columnForNewFilter?.field ?? null;
  };

  const hiddenFields = [`policyId`, `createdInvoiceNumber`];

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter(column => !hiddenFields.includes(column.field))
      .filter(column => column.type !== `actions`)
      .map(column => column.field);
  };

  const tabs: ITab[] = useMemo(() => {
    return [
      {
        name: langauge.generic.action.status.pending,
        onClick: () => setCurrentTab(ActionStatus.PENDING),
        icon: ClockIcon,
        href: `#`,
        current: currentTab === ActionStatus.PENDING,
        badge: actions?.statusTotals[ActionStatus.PENDING] ? {
          text: actions.statusTotals[ActionStatus.PENDING].toString(),
          color: ``,
        } : undefined,
      },
      {
        name: langauge.generic.action.status.approval_pending,
        onClick: () => setCurrentTab(ActionStatus.APPROVAL_PENDING),
        icon: HandRaisedIcon,
        href: `#`,
        current: currentTab === ActionStatus.APPROVAL_PENDING,
        badge: actions?.statusTotals[ActionStatus.APPROVAL_PENDING] ? {
          text: actions.statusTotals[ActionStatus.APPROVAL_PENDING].toString(),
          color: `orange`,
        } : undefined,
      },
      {
        name: langauge.generic.action.status.complete,
        onClick: () => setCurrentTab(ActionStatus.COMPLETE),
        icon: CheckCircleIcon,
        href: `#`,
        current: currentTab === ActionStatus.COMPLETE,
        badge: actions?.statusTotals[ActionStatus.COMPLETE] ? {
          text: actions.statusTotals[ActionStatus.COMPLETE].toString(),
          color: `green`,
        } : undefined,
      },
      {
        name: langauge.generic.action.status.reverted,
        onClick: () => setCurrentTab(ActionStatus.REVERTED),
        icon: BackwardIcon,
        href: `#`,
        current: currentTab === ActionStatus.REVERTED,
        badge: actions?.statusTotals[ActionStatus.REVERTED] ? {
          text: actions.statusTotals[ActionStatus.REVERTED].toString(),
          color: ``,
        } : undefined,
      },
      {
        name: langauge.generic.action.status.failed,
        onClick: () => setCurrentTab(ActionStatus.FAILED),
        icon: ExclamationCircleIcon,
        href: `#`,
        current: currentTab === ActionStatus.FAILED,
        badge: actions?.statusTotals[ActionStatus.FAILED] ? {
          text: actions.statusTotals[ActionStatus.FAILED].toString(),
          color: `red`,
        } : undefined,
      },
      {
        name: `All`,
        onClick: () => setCurrentTab(null),
        icon: MagnifyingGlassCircleIcon,
        href: `#`,
        current: !currentTab,
      },
    ];
  }, [setCurrentTab, currentTab, actions]);

  const heightClass = getTableHeight(rows?.length);

  return (
    <div>
      <Tabs
        tabs={ tabs }
        small={ smallTabs }
      />

      <div className={ heightClass } >
        <DataGridPro
          sx={ {
            border: `none`,
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          } }
          initialState={ {
            columns: {
              columnVisibilityModel: {
                policyId: false,
                createdInvoiceNumber: false,
              },
            },
          } }
          rows={ rows }
          autosizeOnMount
          autosizeOptions={ {
            expand: true,
          } }
          getRowHeight={ () => `auto` }
          columns={ columns }
          loading={ isLoading || isFetching }
          pinnedColumns={ { right: [`actions`] } }
          pageSizeOptions={ [15, 20, 50, 100, 300] }
          disableDensitySelector
          // Checkboxes
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={ setRowSelectionModel }
          rowSelectionModel={ rowSelectionModel }
          localeText={ {
            toolbarExport: rowSelectionModel.length > 0 ? `Export ${rowSelectionModel.length} row${rowSelectionModel.length === 1 ? `` : `s`}` : `Export`,
          } }
          // Pagination
          pagination
          paginationMode={ `server` }
          onPaginationModelChange={ setPaginationModel }
          paginationModel={ paginationModel }
          rowCount={ actions?.total || 0 }
          onCellClick={ onCellClick }
          // Filter
          filterDebounceMs={ 300 }
          filterMode={ `server` }
          onFilterModelChange={ setFilterModel }
          filterModel={ filterModel }
          // Sort
          sortingMode={ `server` }
          onSortModelChange={ setSortModel }
          sortModel={ sortModel }
          // Slots
          slots={ {
            toolbar: CustomToolbar,
          } }
          slotProps={ {
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
            filterPanel: {
              filterFormProps: {
                filterColumns,
              },
              getColumnForNewFilter,
              logicOperators: [GridLogicOperator.And],
            },
            columnsManagement: {
              getTogglableColumns,
            },
            toolbar: {
              currentOrg,
              rowSelectionModel,
            },
          } }
        />
      </div>
    </div>
  );
}

function CustomToolbar({ currentOrg, rowSelectionModel }) {
  const csvOptions: GridCsvExportOptions = {
    fileName: `action_export_${currentOrg?.legalName}`,
    getRowsToExport: () => rowSelectionModel,
    fields: [
      `type`,
      `status`,
      `contact`,
      `invoiceNumber`,
      `quoteNumber`,
      `actionOn`,
      `executedOn`,
      `createdInvoiceNumber`,
    ],
  };

  return (
    <GridToolbarContainer>
      <div className={ `flex justify-between w-full` }>
        <div>
          <GridToolbarFilterButton />

          <GridToolbarColumnsButton />

          <GridToolbarExport
            csvOptions={ csvOptions }
          />
        </div>

        <ActionQuickFilters  />

      </div>
      <GridToolbarQuickFilter
        placeholder={ `Quick search...` }
        helperText={ `Searches contact and invoice` }
        quickFilterParser={ (value: string) => [value] }
        debounceMs={ 350 }
      />
    </GridToolbarContainer>
  );
}
