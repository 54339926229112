import { Case, Default, Switch } from 'react-if';

import { classNames }  from '../lib/classNames';

import { SmallSpinner } from './SmallSpinner';
import { ListItem } from './ListItem';

interface IListItemsProps {
  items: {
    id?: string | number;
    title: string | React.ReactNode;
    description?: string | React.ReactNode;
    secondaryDescription?: string | React.ReactNode;
    footer?: string | React.ReactNode;
    onClick?: (...args: unknown[]) => void
  }[]
  fullWidth?: boolean
  emptyMessage?: string
  loading?: boolean
  className?: string
}

export function ListItems({
  items,
  fullWidth,
  emptyMessage,
  loading,
  className,
}: IListItemsProps) {
  return (
    <div className={
      classNames(
        `bg-white shadow sm:rounded-md`,
        fullWidth ? `w-full` : ` sm:max-w-xl`,
        className,
      )
    }>
      <Switch>
        <Case condition={ loading }>
          <div className={ `flex flex-row justify-center p-6` }>
            <SmallSpinner colored
              size={ `md` }/>
          </div>
        </Case>
        <Case condition={ items.length === 0 }>
          <p className={ `mt-1 text-sm text-gray-500` }>
            { emptyMessage }
          </p>
        </Case>
        <Default>
          {
            () => (
              <ul role={ `list` }
                className={ `divide-y divide-gray-200` }>
                { items.map((item, i) => (
                  <ListItem key={ i }
                    item={ item } />
                )) }
              </ul>
            )
          }
        </Default>
      </Switch>
    </div>
  );
}
