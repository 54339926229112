import React from 'react';

import { classNames } from '../lib/classNames';

const colsClass = {
  1: `sm:grid-cols-1`,
  2: `sm:grid-cols-2`,
  3: `sm:grid-cols-3`,
  4: `sm:grid-cols-4`,
  5: `sm:grid-cols-5`,
  6: `sm:grid-cols-6`,
  7: `sm:grid-cols-7`,
  8: `sm:grid-cols-8`,
  9: `sm:grid-cols-9`,
  10: `sm:grid-cols-10`,
  11: `sm:grid-cols-11`,
  12: `sm:grid-cols-12`,
};

const colsLarge = {
  1: `lg:grid-cols-1`,
  2: `lg:grid-cols-2`,
  3: `lg:grid-cols-3`,
  4: `lg:grid-cols-4`,
  5: `lg:grid-cols-5`,
  6: `lg:grid-cols-6`,
  7: `lg:grid-cols-7`,
  8: `lg:grid-cols-8`,
  9: `lg:grid-cols-9`,
  10: `lg:grid-cols-10`,
  11: `lg:grid-cols-11`,
  12: `lg:grid-cols-12`,
};

const colsVeryLarge = {
  1: `3xl:grid-cols-1`,
  2: `3xl:grid-cols-2`,
  3: `3xl:grid-cols-3`,
  4: `3xl:grid-cols-4`,
  5: `3xl:grid-cols-5`,
  6: `3xl:grid-cols-6`,
  7: `3xl:grid-cols-7`,
  8: `3xl:grid-cols-8`,
  9: `3xl:grid-cols-9`,
  10: `3xl:grid-cols-10`,
  11: `3xl:grid-cols-11`,
  12: `3xl:grid-cols-12`,
};

const gapYClass = {
  1: `gap-y-1`,
  2: `gap-y-2`,
  3: `gap-y-3`,
  4: `gap-y-4`,
  5: `gap-y-5`,
  6: `gap-y-6`,
  7: `gap-y-7`,
  8: `gap-y-8`,
  9: `gap-y-9`,
  10: `gap-y-10`,
  11: `gap-y-11`,
  12: `gap-y-12`,
};

const gapXClass = {
  1: `gap-x-1`,
  2: `gap-x-2`,
  3: `gap-x-3`,
  4: `gap-x-4`,
  5: `gap-x-5`,
  6: `gap-x-6`,
  7: `gap-x-7`,
  8: `gap-x-8`,
  9: `gap-x-9`,
  10: `gap-x-10`,
  11: `gap-x-11`,
  12: `gap-x-12`,
};

interface IGridProps {
  cols?: number
  colsSmallScreen?: number
  colsLargeScreen?: number
  gapY?: number
  gapX?: number
  children: React.ReactNode
  className?: string
}

// Is always one col on mobile
export function Grid({
  cols = 6,
  colsSmallScreen = cols,
  colsLargeScreen = cols, // if very large screen, use this
  gapY = 6,
  gapX = 4,
  children,
  className,
}: IGridProps) {
  return (
    <div
      className={
        classNames(
          `grid grid-cols-1`,
          colsClass[colsSmallScreen],
          colsVeryLarge[colsLargeScreen],
          colsLarge[cols],
          gapXClass[gapX],
          gapYClass[gapY],
          className,
        )
      }>
      { children }
    </div>
  );
}
