import React, { Fragment } from 'react';
import { Case, Switch, When } from 'react-if';
import { Tooltip } from '@mui/material';

import { classNames } from '../lib/classNames';
import { HelpLinkIcon } from '../../main/components/Molecules/HelpLinkIcon';
import { HelpIcon } from '../../main/components/Molecules/HelpIcon';
import { IHeroIcon } from '../../main/types/types';

import { Badge } from './Badge';
import { Button } from './Button';
import { Paragraph } from './Typography/Paragraph';
import { Dropdown } from './Dropdown';

interface IButton {
  buttonText: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: any;
  disabled?: boolean
  loading?: boolean
  tooltip?: string
  caption?: string
  demoTarget?: string
  icon?: IHeroIcon
}

interface IMultiButtonCardHeaderProps {
  badge?: string
  badgeColor?: string
  badgeTooltip?: string
  title: string | React.ReactNode;
  buttons?: IButton[]
  buttonsAsDropdownText?: string;
  secondaryButtons?:IButton[]
  secondaryButtonsAsDropdownText?: string;
  description?: string | React.ReactNode;
  secondaryDescription?: string | React.ReactNode;
  cta?: React.ReactNode;
  children?: React.ReactNode;
  noDivider?: boolean;
  helpLink?: string;
  helpText?: string;
}

function renderButtons(btns?: IButton[], buttonsDropdownText?: string) {
  if (!btns || !btns.length) return null;

  if (buttonsDropdownText) {
    // Render as a dropdown
    const options = btns.map(button => {
      return {
        label: button.buttonText,
        onClick: button.onClick,
        disabled: button.disabled,
        tooltip: button.tooltip,
        loading: button.loading,
      };
    });

    return (
      <Dropdown
        options={ [options] }
        label={ buttonsDropdownText }
        theme={ `primary` }
        position={ `left` }
        size={ `md` }
      />
    );
  }

  return btns.map((button, i) => {
    return (
      <Fragment key={ i + button.buttonText }>
        <Tooltip title={ button.tooltip }>
          <Button
            className={ `ml-2` }
            disabled={ button.disabled }
            onClick={ button.onClick }
            loading={ button.loading }
            size={ `lg` }
          >
            <When condition={ !!button.icon }>
              { () => <button.icon className={ `h-5 w-5 mr-1.5 text-white` } /> }
            </When>
            { button.buttonText }
          </Button>
        </Tooltip>
        <When condition={ !!button.caption }>
          <Paragraph
            className={ `ml-2` }
            variant={ `secondary` }
          >
            { button.caption }
          </Paragraph>
        </When>
      </Fragment>
    );
  });
}

export function MultiButtonCardHeader({
  badge, badgeColor, title, buttons, description, secondaryDescription, cta, children, noDivider, buttonsAsDropdownText, helpLink, secondaryButtons, secondaryButtonsAsDropdownText, helpText, badgeTooltip,
}: IMultiButtonCardHeaderProps) {

  return (
    <div className={ ` bg-white px-4 sm:px-5` }>
      <div className={
        classNames(
          `py-5`,
          noDivider ? `` : `border-b border-gray-200`,
        )
      }>
        <div className={ `-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap` }>
          <div className={ `ml-4 mt-2` }>
            <h3 className={ `text-lg font-medium leading-6 text-gray-900 flex` }>
              { title }
              <When condition={ badge }>
                <Tooltip title={ badgeTooltip || badge }>
                  <Badge
                    className={ `ml-2 w-fit h-fit` }
                    color={ badgeColor }
                    message={ badge }
                  />
                </Tooltip>
              </When>
              <When condition={ !!helpLink }>
                <HelpLinkIcon
                  className={ `ml-2` }
                  href={ helpLink }
                />
              </When>
              <When condition={ !!helpText }>
                <HelpIcon
                  className={ `ml-2` }
                  tooltip={ helpText }
                />
              </When>
            </h3>
            <p className={ `mt-1 text-sm text-gray-500` }>
              { description }
            </p>
            <When condition={ !!secondaryDescription }>
              <p className={ `mt-0.5 text-sm text-gray-500` }>
                { secondaryDescription }
              </p>
            </When>
          </div>
          <Switch>
            <Case condition={ !!cta }>
              {
                () => <>{ cta }</>
              }
            </Case>
            <Case condition={ !!buttons && buttons.length }>
              {
                () => <div className={ `ml-4 mt-2 flex-shrink-0` }>
                  {
                    renderButtons(buttons, buttonsAsDropdownText)
                  }
                  {
                    (!!secondaryButtons && secondaryButtons.length) ? (
                      renderButtons(secondaryButtons, secondaryButtonsAsDropdownText)
                    ) : null
                  }
                </div>
              }
            </Case>
          </Switch>
        </div>
        { children }
      </div>
    </div>
  );
}
