import { useEffect, useMemo, useState } from "react";
import { ContactSortTypes, ContactStatus } from "shared";
import { When } from "react-if";

import { useGroups } from "../../../hooks/useGroups";
import { Grid } from "../../../../common/Atoms/Grid";
import { GridItem } from "../../../../common/Atoms/GridItem";
import { IOption, Select } from "../../../../common/Atoms/Select";
import { InputTextAddon } from "../../../../common/Atoms/InputTextAddon";
import { useContactParams, useContacts } from "../../../hooks/useContacts";
import { useDebounce } from "../../../hooks/useDebounce";
import { useAccountingLanguge } from "../../../hooks/useAccountingLanguage";
import { Button } from "../../../../common/Atoms/Button";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { CheckBox } from "../../../../common/Atoms/CheckBox";
import { MultiSelect } from "../../../../common/Atoms/MultiSelect";
import { useContactTags } from "../../../hooks/useContactTags";

const sortOptions: IOption[] = [
  {
    label: `Name (A-Z)`,
    value: ContactSortTypes.ALPHABETICAL_ASC,
  },
  {
    label: `Name (Z-A)`,
    value: ContactSortTypes.ALPHABETICAL_DESC,
  },
  {
    label: `Unpaid invoices (Low -> High)`,
    value: ContactSortTypes.NUMBER_UNPAID_INVOICES_ASC,
  },
  {
    label: `Unpaid invoices (High -> Low)`,
    value: ContactSortTypes.NUMBER_UNPAID_INVOICES_DESC,
  },
];

const frozenOptions = [
  { label: `Frozen Only`, value: true },
  { label: `Unfrozen Only`, value: false },
];

interface ContactTableFilterProps {
  context?: `group` | `root`;
}

export function ContactFilters({
  context = `root`,
}: ContactTableFilterProps) {
  const { data: groups } = useGroups();
  const accountingLanguage = useAccountingLanguge();
  const { params, updateParams, resetParams } = useContactParams();
  const [searchStr, setSearchStr] = useState(params.searchStr || ``);
  const { data: contacts } = useContacts();
  const { tags, isLoading } = useContactTags();

  const debouncedSearchStr = useDebounce(searchStr, 350);

  useEffect(() => {
    updateParams({
      ...params,
      searchStr: debouncedSearchStr,
      page: 1,
    });
  }, [debouncedSearchStr]);

  useEffect(() => {
    if (params.searchStr !== searchStr) {
      setSearchStr(params.searchStr || ``);
    }

  }, [params.searchStr]);

  const groupOptions = useMemo(() => {
    if (!groups) return [];

    return groups.map(g => ({
      label: g.title,
      value: g.id,
    }));
  }, [groups]);

  const externalAggregateNameOptions = useMemo(() => {
    if (!contacts || !contacts.externalAggregateNames) return [];

    return contacts.externalAggregateNames.filter(name => !!name).map(name => ({
      label: name,
      value: name,
    }));
  }, [contacts]);

  const tagOptions = useMemo(() => {
    if (!tags) return [];

    return tags.map(tag => ({
      label: tag,
      value: tag,
    }));
  }, [tags]);

  const tagDisplay = useMemo(() => {
    if (!params.tags || params.tags.length === 0) return `All`;

    if (params.tags.length === 1) return params.tags[0];

    return `${params.tags.length} tags`;
  }, [params.tags]);

  const filterCount = useMemo(() => {
    let count = 0;

    if (params.groupId) count++;

    if (params.externalAggregateName) count++;

    if (params.searchStr) count++;

    if (params.tags && params.tags.length) count++;

    if (params.onlyNoPhone) count++;

    if (params.onlyWithBalance) count++;

    if (params.onlyWithNoEmail) count++;

    if (params.status !== ContactStatus.ACTIVE) count++;

    return count;
  }, [params]);

  function clearFilters() {
    resetParams();
  }

  return (
    <>
      <Grid
        cols={ 12 }
        className={ `mt-6` }
        gapY={ 1 }
      >
        <GridItem
          span={ context === `root` ? 2 : 4 }
          position={ `bottom` }
        >
          <InputTextAddon
            label={ `Name` }
            onChange={ e => setSearchStr(e.value as string) }
            placeholder={ `Search by name, email or account ID` }
            value={ searchStr }
            className={ `w-full` }
          />
        </GridItem>
        <When condition={ context === `root` }>
          <GridItem
            span={ 2 }
            position={ `bottom` }
          >
            <Select
              label={ `Paidnice Group` }
              options={ groupOptions }
              selected={ params.groupId }
              emptyText={ `All` }
              nullable
              onChange={ e =>  updateParams({
                ...params,
                groupId: e.value,
                page: 1,
              }) }
            />
          </GridItem>
        </When>

        <GridItem
          position={ `bottom` }
          span={ 2 }
        >
          <Select
            label={ accountingLanguage.contacts.externalAggregateNameLabel }
            options={ externalAggregateNameOptions }
            selected={ params.externalAggregateName }
            nullable
            emptyText={ `All` }
            onChange={ e => updateParams({
              ...params,
              externalAggregateName: e.value,
              page: 1,
            }) }
          />

        </GridItem>

        <GridItem
          position={ `bottom` }
          span={ 2 }
        >
          <MultiSelect
            label={ `Tags` }
            options={ tagOptions }
            loading={ isLoading }
            selected={ params.tags }
            display={ tagDisplay }
            clearable
            onChange={ e => updateParams({
              ...params,
              tags: e as string[],
              page: 1,
            }) }
          />

        </GridItem>

        <GridItem
          position={ `bottom` }
          span={ 2 }
        >
          <Select
            label={ `Frozen Status` }
            options={ frozenOptions }
            selected={ params.frozen }
            onChange={ e => updateParams({
              ...params,
              frozen: e.value,
              page: 1,
            }) }
            nullable
            emptyText={ `All` }
          />
        </GridItem>

        <GridItem
          position={ `bottom` }
          className={ `` }
          span={ 2 }
        >
          <Button
            onClick={ clearFilters }
            className={ `mb-1` }
            disabled={ filterCount === 0 }
          >
            { `Clear ${filterCount} filter${filterCount === 1 ? `` : `s`}` }
          </Button>
        </GridItem>
      </Grid>

      <div className={ `flex justify-between` }>
        { /* Checkboxes */ }
        <div className={ `flex space-x-6 mt-1.5` }>
          <CheckBox
            label={ `Show archived` }
            checked={ params.status !== ContactStatus.ACTIVE }
            placement={ `left` }
            onChange={ e => updateParams({
              ...params,
              status: e.value ? null : ContactStatus.ACTIVE,
              page: 1,
            }) }
          />
          <CheckBox
            label={ `Outstanding balance only` }
            checked={ params.onlyWithBalance || false }
            placement={ `left` }
            onChange={ e => updateParams({
              ...params,
              onlyWithBalance: e.value ? true : null,
              page: 1,
            }) }
          />

          <CheckBox
            label={ `No primary email` }
            checked={ params.onlyWithNoEmail || false }
            placement={ `left` }
            onChange={ e => updateParams({
              ...params,
              onlyWithNoEmail: e.value ? true : null,
              page: 1,
            }) }
          />
          <CheckBox
            label={ `No primary phone` }
            checked={ params.onlyNoPhone || false }
            placement={ `left` }
            onChange={ e => updateParams({
              ...params,
              onlyNoPhone: e.value ? true : null,
              page: 1,
            }) }
          />
        </div>

        <div className={ `w-[190px] mt-4` }>
          <Select
            selected={ params.sortBy }
            options={ sortOptions }
            onChange={ e => updateParams({
              ...params,
              sortBy: e.value as ContactSortTypes,
              page: 1,
            }) }
          />
        </div>
      </div>

      <When condition={ context === `root` }>
        <Paragraph
          variant={ `help` }
        >
          { `Use these filters to find contacts, then move them into a group by selecting them and using the below dropdown` }
        </Paragraph>
      </When>
    </>
  );
}
